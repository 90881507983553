/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getCookie } from '../../utils/Cookies/CookieHandler';
import Label from '../ui/label/Label'
import DropdownSelect from '../ui/dropdown/DropDrown';
import { useLazyGetServiceLocationQuery } from '../../app/services/commonAPI';

const ServiceLocationFilter = (props) => {

    const cookie = getCookie("AuthUser");
    const userID = cookie?.user?.lUserKey;

    const [locationList, setLocationList] = useState(null);

    const [getServiceLoaction, {
        isFetching: isLocationFetching,
        isSuccess: isLocationFetched,
        data: locationData
    }] = useLazyGetServiceLocationQuery();

    useEffect(() => {
        if (userID) {
            getServiceLoaction(userID);
        }
    }, [userID]);

    const handleChange = (selected) => {
        if (props.onLocationChange) {
            props.onLocationChange(selected)
        }
    }

    useEffect(() => {
        if (!isLocationFetching && isLocationFetched && locationData) {
            const dropDownField = locationData?.map((item) => ({
                label: item.sServiceLocation,
                value: item.lServiceLocationKey
            }));
            // setLocationList([...dropDownField, ...selectValue])
            setLocationList(dropDownField)
            // setSelectedLocation(dropDownField[0]?.value)
        }
    }, [isLocationFetching, isLocationFetched, locationData])

    return (
        <div className="input-label-part">
            <Label labelName="Service Location :" />
            <DropdownSelect
                value={props.selectedLocation}
                options={locationList}
                placeholder="Select Location"
                // onChange={(selected) => setSelectedLocation(selected.value)}
                onChange={handleChange}
                isDisabled={props.isDisable}
            />
        </div>
    )
}

export default ServiceLocationFilter